import { push } from 'connected-react-router';
import { all, call, ForkEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { setEnrollmentEventProfileComplete } from 'Containers/App/actions';
import { makeSelectConfigField, makeSelectGlobalField, makeSelectJvpField } from 'Containers/App/selectors';
import { AlexIdUser } from 'Containers/App/types';
import { Household, HouseholdMember } from 'Types/entities';
import {
  apiCreateOrUpdateHousehold,
  getDrugs,
  getDrugsByNdc,
  getHealthFundIncentiveSurvey,
  getHousehold,
  getIntegratedUserProfile,
  getLocationsByZip,
  getPreviousSelectedPlan,
} from 'Utils/api';
import * as API_TYPES from 'Utils/apiTypes';
import { JVPEnrollmentEvent, PreviousSelectedPlan } from 'Utils/apiTypes';
import Logger from 'Utils/logger';
import { ERROR_PATH } from 'Utils/urls';

import {
  createOrUpdateHouseholdFailure,
  createOrUpdateHouseholdSuccess,
  getAlexIdUserProfileFailure,
  getAlexIdUserProfileSuccess,
  getHouseholdError,
  getHouseholdSuccess,
  getIncentiveSurveyFailure,
  getIncentiveSurveySuccess,
  searchDrugsFailure,
  searchDrugsSuccess,
  searchLocationsFailure,
  searchLocationsSuccess,
} from './actions';
import {
  CREATE_OR_UPDATE_HOUSEHOLD_REQUEST,
  CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS,
  GET_HOUSEHOLD_REQUEST,
  PREFILL_USER_PROFILE_REQUEST,
  SEARCH_DRUGS_REQUEST,
  SEARCH_LOCATIONS_REQUEST,
} from './constants';
import {
  makeGetHousehold,
  makeGetHouseholdId,
  makeGetSelectedEnrollmentEvent,
  makeGetSelectedEnrollmentEventId,
  makeGetSelectedPublicationKey,
  makeSelectClientSurvey,
  makeSelectProfileField,
} from './selectors';
import { MemberSectionConfigFields } from './types';

export function* getXhrHousehold(action) {
  const memberConfig: MemberSectionConfigFields = action.memberConfig;
  const householdId = yield select(makeGetHouseholdId());
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());

  try {
    const resp: Household = yield call(getHousehold, householdId, enrollmentEventId);
    yield put(getHouseholdSuccess(resp, memberConfig));
  } catch (err) {
    yield put(getHouseholdError(err));
  }
}

/* Location sagas */
export function* getXhrLocationsByZip(action) {
  try {
    const resp: API_TYPES.GetLocationsResponse = yield call(getLocationsByZip, action.zipcode);
    yield put(searchLocationsSuccess(resp));
  } catch (err) {
    yield put(searchLocationsFailure(err));
  }
}

/* Prescription sagas */

export function* getXhrDrugs(action) {
  try {
    const resp: API_TYPES.GetDrugsResponse = yield call(getDrugs, action.query);
    yield put(searchDrugsSuccess(resp));
  } catch (err) {
    yield put(searchDrugsFailure(err));
  }
}

export function* getXhrAlexIdUserProfile(action) {
  const memberConfig = action.memberConfig;
  const user: AlexIdUser = yield select(makeSelectGlobalField('user'));
  const jvpUser: API_TYPES.JVPUserWithDependents = yield select(makeSelectGlobalField('jvpUser'));
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
  const publicationKey = yield select(makeGetSelectedPublicationKey());
  const clientSurveyConfig = yield select(makeSelectClientSurvey());
  const isReturnUser = yield select(makeSelectJvpField('isReturnUser'));
  const feature_flag = yield select(makeSelectConfigField('split_feature_flags'));
  const jvpEligibilityQuestions = yield select(makeSelectProfileField('jvpEligibilityQuestions'));

  const validClientSurveyQuestionIds: string[] = [];
  if (clientSurveyConfig?.properties) {
    validClientSurveyQuestionIds.push(...Object.keys(clientSurveyConfig.properties));
  }

  try {
    // Fetch household from Comm API (for now; future state should be JVP)
    const household: Household = yield call(() => getIntegratedUserProfile(user, enrollmentEventId));
    if (!household) {
      throw new Error('Comm API household returned undefined');
    }

    let drugDetails: API_TYPES.GetBatchDrugDetailsResponse | null = null;
    let previousPlan: PreviousSelectedPlan[] = [];

    try {
      if (feature_flag.is_returning_user_feature_enabled && enrollmentEventId) {
        // Fetch previous year selected plans
        const respPrevPlan = yield call(() => getPreviousSelectedPlan(enrollmentEventId));
        previousPlan = respPrevPlan?.elections;
      }

      if (household.members.some((member) => member.prescriptions)) {
        const ndcsForLookup = getFlatNdcArray(household.members);
        if (ndcsForLookup.length > 0) {
          drugDetails = yield call(() => getDrugsByNdc(ndcsForLookup));
        }
      }
    } catch (rxError) {
      // We will not prefill prescriptions if there is an error
      Logger.log(`Failed to fetch drug details by NDC: ${rxError}`);
    }
    yield put(
      getAlexIdUserProfileSuccess(
        household,
        publicationKey,
        validClientSurveyQuestionIds,
        jvpUser,
        drugDetails,
        memberConfig,
        previousPlan,
        isReturnUser,
        jvpEligibilityQuestions,
      ),
    );
  } catch (err) {
    yield put(getAlexIdUserProfileFailure(err));
  }
}

function getFlatNdcArray(members: HouseholdMember[]): string[] {
  const ndcSet = new Set(members.flatMap((member) => member.prescriptions.map((rx) => rx.ndc)));
  return Array.from(ndcSet);
}

// get incentive survey request
export function* getXhrIncentiveSurveyRequest() {
  try {
    const householdId = yield select(makeGetHouseholdId());
    const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
    const resp: API_TYPES.IncentiveSurveyResponse = yield call(
      getHealthFundIncentiveSurvey,
      householdId,
      enrollmentEventId,
    );
    yield put(getIncentiveSurveySuccess(resp));
  } catch (err) {
    yield put(getIncentiveSurveyFailure(err as Error));
    yield put(push(ERROR_PATH));
  }
}

export function* createOrUpdateHousehold() {
  try {
    const household = yield select(makeGetHousehold());
    const enrollmentEvent: JVPEnrollmentEvent = yield select(makeGetSelectedEnrollmentEvent());
    const householdId = yield select(makeGetHouseholdId());
    const builderCustomerKey = yield select(makeSelectConfigField('builder_customer_key'));

    // if hh_id is passed, update household. otherwise, create a new household.
    const resp: API_TYPES.ItemReference = yield call(
      apiCreateOrUpdateHousehold,
      household,
      householdId,
      enrollmentEvent?.id,
    );

    yield put(createOrUpdateHouseholdSuccess(resp, builderCustomerKey));

    if (enrollmentEvent) {
      // When household is created successfully,
      // update enrollment event setting 'profile_complete' to true
      const employeeId = yield select(makeSelectJvpField('employeeId'));
      const eventType = yield select(makeSelectJvpField('eventType')) || 'none';

      yield put(
        setEnrollmentEventProfileComplete(employeeId, enrollmentEvent.jv_publication_uuid, eventType, true),
      );
    }
  } catch (err: unknown) {
    yield put(createOrUpdateHouseholdFailure(err as Error));
    yield put(push(ERROR_PATH));
  }
}

export default function* getProfileSaga() {
  // Determine whether to prepopulate profile forms
  const prepopulateProfile = yield select(makeSelectConfigField('prepopulate_profile'));
  const builderCustomerKey = yield select(makeSelectConfigField('builder_customer_key'));
  const sagas: ForkEffect[] = [];

  if (prepopulateProfile) {
    sagas.push(takeLatest(GET_HOUSEHOLD_REQUEST, getXhrHousehold));
  }

  sagas.push(takeLatest(SEARCH_LOCATIONS_REQUEST, getXhrLocationsByZip));
  sagas.push(takeLatest(SEARCH_DRUGS_REQUEST, getXhrDrugs));

  sagas.push(takeLatest(PREFILL_USER_PROFILE_REQUEST, getXhrAlexIdUserProfile));

  sagas.push(takeLatest(CREATE_OR_UPDATE_HOUSEHOLD_REQUEST, createOrUpdateHousehold));

  if (builderCustomerKey) {
    sagas.push(takeEvery(CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS, getXhrIncentiveSurveyRequest));
  }
  yield all(sagas);
}

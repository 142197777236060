import { useAuth0 } from '@auth0/auth0-react';
import { Stack, useDisclosure } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { handleUnlockMainNav, updatePreviousSelectedPlan } from 'Containers/App/actions';
import { COVERAGE_TIERS, GO_GUEST_ID } from 'Containers/App/constants';
import {
  makeIsPrevPlanSelected,
  makePreviousSelectedPlan,
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectGlobalField,
  makeSelectJvpField,
  selectIsFirstPageView,
} from 'Containers/App/selectors';
import { AlexProducts, JVPState } from 'Containers/App/types';
import {
  defaultCookieDomain,
  getCookie,
  getInvalidPlanMessage,
  moveSelectedPlanToTop,
  setCookie,
  stripSourceComponent,
} from 'Containers/App/utils';
import {
  changeFormValue,
  getResultsPageData,
  recommendWithEphemeralPlan,
  selectHealthPlan,
  selectHealthPlanStoreOnly,
  setSelectedMemberIds,
  waiveHealthCoverage,
} from 'Containers/CommercialRoutes/actions';
import { makeGetHsaContributionLimit, makeGetPlans } from 'Containers/CommercialRoutes/selectors';
import { EnteredPlan } from 'Containers/CommercialRoutes/types';
import {
  clearSupplementalPlansCache,
  getSupplementalPlans,
  setDefaultSelectedPlans,
} from 'Containers/OverviewPage/actions';
import { makeSelectOverviewField } from 'Containers/OverviewPage/selectors';
import {
  changeSurveyValueFromResultPage,
  setActiveHouseholdMembers,
  setIncentiveSurvey,
  submitResultIncentiveSurvey,
  updateHouseholdFromResultPage,
} from 'Containers/ProfilePage/actions';
import {
  makeGetBuilderGoSetting,
  makeGetBuilderGoSettings,
  makeGetCoverageTier,
  makeGetHasSpouse,
  makeGetHealthPlanMemberIds,
  makeGetHousehold,
  makeGetIsFamily,
  makeGetSelectedProduct,
  makeGetSelectedPublicationPlanYear,
  makeGetSelectedPublicationSegmentSlug,
  makeGetTierCodeData,
  makeSelectProfileField,
  makeSelectShowClientSurvey,
  makeSelectSurvey,
} from 'Containers/ProfilePage/selectors';
import {
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  ResultPageIncentiveSurveyData,
  Survey,
} from 'Containers/ProfilePage/types';
import ClientDisclaimer from 'Containers/ResultPage/ClientDisclaimer';
import { ContentfulTextNoteType } from 'ContentfulDefaults/types/_shared';
import { ContentfulTextNote } from 'ContentfulWrappers/ContentfulTextNote';
import { ContentfulHeaderWrapperForHealthSection } from 'ContentfulWrappers/header';
import { useCompareContext } from 'Contexts/compareContext';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp, Spinner } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { useViewport } from 'DesignLibrary/context';
import Plan from 'Models/plan';
import {
  Household,
  invalidPlanMessageType,
  ProfileIncentiveQuestionAnswers,
  Recommendation as RecommendationType,
  SupplementalPlansFullHousehold,
} from 'Types/entities';
import { AriaHiddenAlert } from 'Utils/accessibility';
import { BuilderGoSettings, PeopleLikeYou, PreviousSelectedPlan } from 'Utils/apiTypes';
import { formatBuilderPlansForInsights, sendInsightsEvent } from 'Utils/insights';
import Logger from 'Utils/logger';
import { getBCPath, OVERVIEW_PATH, PROFILE_PATH, RESULT_PATH, VIEW_PLAN_PATH } from 'Utils/urls';

import { AccountsBanner, BuildAPlanBanner, PredictionsBanner, WaiveBar } from './_banner/BannerCards';
import CompareBar from './CompareBar';
import CompareModal from './CompareModal';
import ExplainerModal from './ExplainerModal';
import InvalidPlanSelectionMessage from './InvalidPlanSelectionMessage';
import { LoadingScreen } from './LoadingScreen';
import { MemberSelectionCard } from './MemberSelectionCard';
import { MemberDivider } from './MemberSelectionCard/styled';
import NoEligiblePlans from './NoEligiblePlans';
import PlanEntryModal from './PlanEntryModal';
import { ProviderSelectionContent } from './ProviderSelectionContent';
import Recommendation from './Recommendation';
import {
  BottomCards,
  GridContainer,
  HorizontalScrollBar,
  MemberLoadingContainer,
  PlanCards,
  PlanChangeMessage,
  Sidebar,
} from './styled';

// Both the objects have same value but there type is different
export interface MatchedPlans {
  previousSelectedMatchedPlan: PreviousSelectedPlan;
  matchingPlan: RecommendationType;
}

interface ResultPageStateProps {
  client: string;
  selectedProduct: AlexProducts | '';
  availablePlans: RecommendationType[] | [];
  maximumAvailableAnnualSihraContribution: number;
  peopleLikeYou: PeopleLikeYou | Record<string, never>;
  isFamily: boolean;
  hasSpouse: boolean;
  showClientSurvey: boolean;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  hsaContributionLimit: number;
  isDirty: boolean;
  isLoading: boolean;
  isPLYEnabled: boolean;
  killMedRec: boolean;
  selectedPlan: RecommendationType | Record<string, never>;
  isFullBenefitEnabled: boolean;
  canWaiveCoverage: boolean;
  household: Household;
  enteredPlan: EnteredPlan;
  isSpousalPlanEnabled: boolean;
  isPlanComparisonEnabled: boolean;
  supplementalPlans: SupplementalPlansFullHousehold;
  isFirstPageView: boolean;
  searchProviderLinks: Record<string, string>;
  selectedMemberIds: string[];
  survey: Survey;
  isCoverageWaived: boolean;
  coverageTier: COVERAGE_TIERS;
  jvp: JVPState;
  isDemoMode: boolean;
  planYear: number | undefined;
  validatedSegmentSlug: string | undefined;
  bcUrlHash: string;
  builderCustomerKey: string | null;
  incentiveSurvey: IncentiveSurveyQuestion[];
  isIncentiveSurveyLoading: boolean;
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
  resultPageIncentiveSurvey: ResultPageIncentiveSurveyData;
  isReturnUser: boolean | null;
  previousSelectedPlans: PreviousSelectedPlan[];
  tierCode: string[];
  isPlanSelected: boolean;
  builderGoSettings: BuilderGoSettings | null;
}

interface ResultPageDispatchProps {
  lockFuturePages: () => void;
  viewPlanDetails: (planId: string) => void;
  goToClientSurvey: () => void;
  getRecommendations: () => void;
  getRecommendationsWithEphemeralPlan: (plan: EnteredPlan) => void;
  saveEphemeralPlan: (plan: EnteredPlan) => void;
  waiveCoverage: () => void;
  selectHealthPlan: (plan: RecommendationType | Record<string, never>) => void;
  selectHealthPlanStoreOnly: (plan: RecommendationType | Record<string, never>) => void;
  goToSupplemental: () => void;
  fetchSupplementalPlans: () => void;
  setSelectedMemberIds: (memberIds: string[]) => void;
  handleSavePregnancyAnswer: (value: string) => void;
  resetSelectedSupplementalPlans: () => void;
  clearSupplementalCache: () => void;
  createUpdateHouseholdAndIncentiveSurvey: () => void;
  submitIncentiveSurveyResponses: (incentiveSurveyResonses) => void;
  updateActiveMembers: (member: string[]) => void;
  setProfileIncentiveSurvey: (data: ProfileIncentiveQuestionAnswers) => void;
  updatePreviousSelectedPlan: (data: PreviousSelectedPlan[] | []) => void;
}
export type ResultPageProps = ResultPageStateProps & ResultPageDispatchProps;

export const ResultPage = ({
  client,
  selectedProduct,
  availablePlans,
  maximumAvailableAnnualSihraContribution,
  peopleLikeYou,
  isFamily,
  hasSpouse,
  showClientSurvey,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  hsaContributionLimit,
  isDirty,
  isLoading,
  isPLYEnabled,
  killMedRec,
  selectedPlan,
  isFullBenefitEnabled,
  canWaiveCoverage,
  household,
  enteredPlan,
  isSpousalPlanEnabled,
  isPlanComparisonEnabled,
  supplementalPlans,
  isFirstPageView,
  searchProviderLinks,
  selectedMemberIds,
  survey,
  isCoverageWaived,
  coverageTier,
  planYear,
  validatedSegmentSlug,
  bcUrlHash,
  jvp,
  isDemoMode,
  builderCustomerKey,
  incentiveSurvey,
  isIncentiveSurveyLoading,
  incentiveSurveyAnswers,
  lockFuturePages,
  viewPlanDetails,
  goToClientSurvey,
  getRecommendations,
  getRecommendationsWithEphemeralPlan,
  saveEphemeralPlan,
  waiveCoverage,
  selectHealthPlan,
  selectHealthPlanStoreOnly,
  goToSupplemental,
  fetchSupplementalPlans,
  setSelectedMemberIds,
  handleSavePregnancyAnswer,
  resetSelectedSupplementalPlans,
  clearSupplementalCache,
  createUpdateHouseholdAndIncentiveSurvey,
  submitIncentiveSurveyResponses,
  updateActiveMembers,
  setProfileIncentiveSurvey,
  updatePreviousSelectedPlan,
  resultPageIncentiveSurvey,
  isReturnUser,
  previousSelectedPlans,
  tierCode,
  isPlanSelected,
  builderGoSettings,
}: ResultPageProps) => {
  const { resetComparedPlans } = useCompareContext();
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();
  const { handleSectionChange, selectedPublicationInfo } = useProfileContext();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { is_returning_user_feature_enabled, is_educational_videos_enabled } = useFeatureFlagContext();

  useEffect(() => {
    const profileIncentiveSurvey = [...incentiveSurvey];
    return () => {
      setProfileIncentiveSurvey({ incentiveSurvey: profileIncentiveSurvey, incentiveSurveyAnswers });
    };
  }, []);

  const isDevMode = localStorage.getItem('dev');
  // ----------------------
  // LOADING INFO
  // ----------------------
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowResults, setIsShowResults] = useState(true);
  const [isMinLoadTimeElapsed, setIsMinLoadTimeElapsed] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [showPreviousSelectedPlanMessage, setShowPreviousSelectedPlanMessage] = useState(true);
  const [isDefaultPrevSelected, setIsDefaultPrevSelected] = useState(true);

  // communicates information to screen readers when value changes
  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);

  const { is_improved_recommendation_explanation_enabled } = useFeatureFlagContext();

  // display provider search section feature enabled from alex builder
  const docFinderEnabled = selectedPublicationInfo?.publication?.docfinder_enabled;

  const selectedProductIsGo = selectedProduct === 'go';
  const selectedProductIsBc = selectedProduct === 'bc';

  const isImprovedRecExplanationEnabled =
    selectedProductIsGo && is_improved_recommendation_explanation_enabled;

  const accessibilityContent = retrieveContentfulData<Record<string, string>>(
    'health_section.secondary_content.accessibility',
  );

  useEffect(() => {
    const shouldShowLoading = isDirty || enteredPlan.isDirty;
    if (!isShowLoading && shouldShowLoading && !isDevMode) {
      setIsShowLoading(true);
      setIsShowResults(false);
      setHiddenAlert(accessibilityContent.loading_bar_alert);
    }
  }, [isDirty, enteredPlan?.isDirty]);

  useEffect(() => {
    if (isLoading) {
      setHiddenAlert(accessibilityContent.loading_bar_alert);
      setIsMinLoadTimeElapsed(true);
      setTimeout(() => {
        setIsMinLoadTimeElapsed(false);
      }, 3000);
    } else if (isShowResults && didMount) {
      // TODO - reorganize this whole page's render order so we don't need to
      //  sidestep first tick render issues with this didMount variable
      setHiddenAlert(accessibilityContent.loading_bar_complete_alert);

      // *** HANDLE BC REDIRECT HERE ***
      if (selectedProductIsBc) {
        if (jvp.sessionId) {
          setCookie('session_id', jvp.sessionId, defaultCookieDomain(), '/', 2);
        }

        // Create a "guest user ID" cookie for guest users
        if (!isAuthenticated && jvp.userId) {
          // BC is expecting a 32 char identifier here
          const goGuestIdForCookie = stripSourceComponent(jvp.userId);
          setCookie(GO_GUEST_ID, goGuestIdForCookie, defaultCookieDomain(), '/');
        }

        // Create a JWT cookie for AAPI/BC for logged in users
        if (isAuthenticated) {
          getIdTokenClaims()
            .then((token) => {
              const idToken = token?.__raw;
              if (idToken) {
                setCookie('jv_jwt', idToken, defaultCookieDomain(), '/', 1, true);
              }
            })
            .catch((tokenError) => Logger.error(tokenError));
          const authCookieName = `auth0.${window._env_.REACT_APP_AUTH0_CLIENT_ID}.is.authenticated`;
          const auth0CookieValue = getCookie(authCookieName);
          setCookie(authCookieName, auth0CookieValue, defaultCookieDomain(), '/', 1440);
        }

        const bcPath = getBCPath(isDemoMode, planYear?.toString() || '', validatedSegmentSlug, bcUrlHash);

        window.onbeforeunload = null;
        window.location.assign(bcPath);
      } else if (availablePlans.length === 0) {
        goToSupplemental();
      }
    }
  }, [isLoading, isShowResults]);

  const isHouseholdLoading = !isDevMode && (isLoading || isMinLoadTimeElapsed);

  const handleOnLoadingComplete = () => {
    const SHOW_RESULTS = 1000; // Slight delay before showing results

    setIsShowLoading(false);

    setTimeout(() => {
      setIsShowResults(true);
      setHiddenAlert(accessibilityContent.loading_bar_complete_alert);
    }, SHOW_RESULTS);
  };

  // ----------------------
  // MODAL WINDOW STATE
  // ----------------------
  const { isOpen: hsaOpen, onOpen: hsaOnOpen, onClose: hsaOnClose } = useDisclosure();
  const { isOpen: hraOpen, onOpen: hraOnOpen, onClose: hraOnClose } = useDisclosure();
  const { isOpen: fsaOpen, onOpen: fsaOnOpen, onClose: fsaOnClose } = useDisclosure();
  const { isOpen: predictionsOpen, onOpen: predictionsOnOpen, onClose: predictionsOnClose } = useDisclosure();
  const { isOpen: planEntryOpen, onOpen: planEntryOnOpen, onClose: planEntryOnClose } = useDisclosure();
  const { isOpen: compareOpen, onOpen: compareOnOpen, onClose: compareOnClose } = useDisclosure();

  useEffect(() => {
    if (isDirty) {
      resetComparedPlans(); // reset compared plans if profile is dirty
      getRecommendations(); // only request recommendations if profile is dirty
      resetSelectedSupplementalPlans(); // reset selected fbs plans if profile is dirty
      clearSupplementalCache(); // reset supplemental plans cache if profile is dirty
    }

    sendInsightsEvent(null, 'result_page');
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (availablePlans.length > 0 && !isDirty) {
      // Need to fetch supplemental plans to determine whether or not FSA info modal button should display
      //   -Trigger in this useEffect to prevent calling fetchSupplemental before getRecommendations
      fetchSupplementalPlans();

      const builderPlans = formatBuilderPlansForInsights(availablePlans);

      sendInsightsEvent(null, 'recommended_plan', {
        recommendations: builderPlans,
      });
    }
  }, [isDirty]);

  // Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api, APP-806
  const { is_real_cost_before_tax_benefit_enabled } = useFeatureFlagContext();

  // TODO: move this to a selector
  function getEmployerHSAContributionMax() {
    if (isPlanSelected && selectedPlan.plan.hsa_eligible) {
      const plan = new Plan(selectedPlan as RecommendationType);
      return plan.calcEmployerHsaContribution(hsaContributionLimit);
    }
    return Math.max(
      ...availablePlans.map((plan) => new Plan(plan).calcEmployerHsaContribution(hsaContributionLimit) || 0),
    );
  }

  const employerHSAContributionMax = getEmployerHSAContributionMax();

  // TODO: move this to a selector
  function getEmployerHRAContributionMax() {
    if (isPlanSelected && selectedPlan.plan.hra_eligible) {
      const plan = new Plan(selectedPlan as RecommendationType);
      return plan.employerHraContribution;
    }
    return Math.max(...availablePlans.map((plan) => new Plan(plan).employerHraContribution || 0));
  }

  const employerHRAContributionMax = getEmployerHRAContributionMax();

  const handleSelectHealthPlan = (recommendation) => {
    // Create a shallow copy of the recommendation object
    const updatedRecommendation = { ...recommendation };
    updatedRecommendation.publication = survey.enrollment_question.split('-')[0];
    // set selected plan on Commercial reducer + post enrollment data
    selectHealthPlan(updatedRecommendation);
    setHiddenAlert(accessibilityContent.plan_selected_alert);
  };

  const handleEphemeralPlanRecommendation = (plan) => {
    resetComparedPlans();
    getRecommendationsWithEphemeralPlan(plan);
  };

  const handleDeselect = () => {
    updatePreviousSelectedPlan([]);
    // set deselected plan on Commercial reducer + post enrollment data
    setIsDefaultPrevSelected(false);
    selectHealthPlan({});
    lockFuturePages();
  };

  const handleWaiveHealthPlan = () => {
    setIsDefaultPrevSelected(false);
    updatePreviousSelectedPlan([
      {
        planable_type: 'MedicalPlan',
        waived: true,
      },
    ]);
    selectHealthPlan({});
    waiveCoverage();
    setHiddenAlert(accessibilityContent.waived_coverage_alert);
  };

  // TODO: TICKETED Add insights events to individual modal windows
  // const handleOpenInfoModal = (infoType) => {
  //   sendInsightsEvent(null, 'info_modal_view_started', { modal_id: infoType });
  // };

  // const handleCloseInfoModal = (infoType) => {
  //   sendInsightsEvent(null, 'info_modal_view_ended', { modal_id: infoType });
  // };

  let hasEnteredCustomPlan = false;
  availablePlans.forEach(({ plan }) => {
    if (plan.plan_type === 'Custom') hasEnteredCustomPlan = true;
  });

  // ----------------------
  // SECONDARY CONTENT
  // ----------------------

  const hasHsaPlan = availablePlans.some((rec) => rec.plan.hsa_eligible);
  const hasHraPlan = availablePlans.some((rec) => rec.plan.hra_eligible);
  const isFsaEligible =
    supplementalPlans?.fsa_medical?.plans?.length ||
    supplementalPlans?.fsa_dependent?.plans?.length ||
    supplementalPlans?.fsa_adoption?.plans?.length;

  const accountsBannerCard = (
    <AccountsBanner
      hasHsaPlan={hasHsaPlan}
      hasHraPlan={hasHraPlan}
      isFsaEligible={isFsaEligible}
      handleHsa={hsaOnOpen}
      handleHra={hraOnOpen}
      handleFsa={fsaOnOpen}
    />
  );

  // Only show when is turned on and killMedRec is false
  const predictionsBannerCard = isPLYEnabled && !killMedRec && (
    <PredictionsBanner handleOpen={predictionsOnOpen} key="predictions" />
  );

  const buildPlanBannerCard = isSpousalPlanEnabled && hasSpouse && (
    <BuildAPlanBanner handleOpen={planEntryOnOpen} key="buildaplan" />
  );

  const providersSearchBannerCard = docFinderEnabled && <ProviderSelectionContent />;

  const renderWaiveCoverage = canWaiveCoverage && isFullBenefitEnabled && (
    <WaiveBar handleWaiveHealthPlan={handleWaiveHealthPlan} isCoverageWaived={isCoverageWaived} key="waive" />
  );

  const renderMemberSelectionCard = household.members.length > 1 && (
    <MemberSelectionCard
      allHouseholdMembers={household.members}
      selectedMemberIds={selectedMemberIds}
      hasEnteredCustomPlan={hasEnteredCustomPlan}
      isBuilderClient={Boolean(builderCustomerKey)}
      isLoading={isHouseholdLoading}
      survey={survey}
      updateActiveMembers={updateActiveMembers}
      incentiveSurvey={incentiveSurvey}
      isIncentiveSurveyLoading={isIncentiveSurveyLoading}
      incentiveSurveyAnswers={incentiveSurveyAnswers}
      createUpdateHouseholdAndIncentiveSurvey={createUpdateHouseholdAndIncentiveSurvey}
      submitIncentiveSurveyResponses={submitIncentiveSurveyResponses}
      handleSectionChange={(key, isValid, isComplete) => handleSectionChange({ key, isValid, isComplete })}
      setSelectedMemberIds={setSelectedMemberIds}
      lockFuturePages={lockFuturePages}
      handleSavePregnancyAnswer={handleSavePregnancyAnswer}
      key="memberSelection"
      setProfileIncentiveSurvey={setProfileIncentiveSurvey}
      resultPageIncentiveSurvey={resultPageIncentiveSurvey}
    />
  );

  // ----------------------
  // BUILDING RECOMMENDATIONS
  // ----------------------

  // PoC for showing the previouslySelectedPlan
  const showPrevSelectedPlan =
    is_returning_user_feature_enabled &&
    isDefaultPrevSelected &&
    isReturnUser &&
    previousSelectedPlans?.length > 0;

  const invalidPlanMessage = useRef<invalidPlanMessageType>(null);
  const result: MatchedPlans | null = previousSelectedPlans?.reduce(
    (selectedData: MatchedPlans | null, previousPlan: PreviousSelectedPlan) => {
      if (!is_returning_user_feature_enabled) return null;
      if (selectedData) return selectedData;
      if (previousPlan.planable_type === 'MedicalPlan') {
        const matchingPlan = availablePlans?.find(
          (availablePlan) =>
            previousPlan?.plan_details?.jv_medical_plan_id === availablePlan?.plan?.external_id,
        );
        if (matchingPlan) {
          return { previousSelectedMatchedPlan: previousPlan, matchingPlan };
        }
      }
      return selectedData;
    },
    null,
  );

  const effectRunRef = useRef(false);
  const selectPlanEmptyRef = useRef(false);

  useEffect(() => {
    if (availablePlans?.length > 0 && Object.keys(selectedPlan).length > 0) {
      const isPlanAvailable = availablePlans?.some(
        (availablePlan) => selectedPlan?.plan?.external_id === availablePlan?.plan?.external_id,
      );

      if (!isPlanAvailable && !selectPlanEmptyRef.current) {
        selectHealthPlanStoreOnly({});
        selectPlanEmptyRef.current = true;
      }
    }
    if (result?.previousSelectedMatchedPlan && !effectRunRef.current) {
      if (result?.matchingPlan && !isPlanSelected) {
        if (!household?.external_id?.startsWith('guest__')) {
          // 1) If the user is NOT a guest, select the matching plan
          /* 2) If the user is a guest, and when he selects one plan go to profile page makes changes then again come to result page,
        then plan will not be selected if its not available in plans also when he goes to profile page keeps changes as it was previous then also no plan needs
        to be shown selected as this functionality will only be for logged in user */
          handleSelectHealthPlan(result?.matchingPlan);
        }
        effectRunRef.current = true;
      }
    } else if (
      previousSelectedPlans?.length > 0 &&
      previousSelectedPlans[0].planable_type === 'MedicalPlan' &&
      previousSelectedPlans[0].waived &&
      !isPlanSelected &&
      !effectRunRef.current
    ) {
      handleWaiveHealthPlan();
      effectRunRef.current = true; // Mark the effect as run
    }

    if (isReturnUser) {
      // Invalidate message
      if (showPreviousSelectedPlanMessage && !isEmpty(availablePlans)) {
        invalidPlanMessage.current = getInvalidPlanMessage(
          tierCode,
          !isEmpty(previousSelectedPlans),
          result?.previousSelectedMatchedPlan,
        );
        if (invalidPlanMessage.current === 'noLongerEligible' && !selectPlanEmptyRef.current) {
          selectHealthPlanStoreOnly({});
          selectPlanEmptyRef.current = true; // Mark the effect as run
        }
      }

      // show/hide previous selected plan
      const matchedWithSelectedPlan = previousSelectedPlans?.some(
        (plan) =>
          plan.planable_type === 'MedicalPlan' &&
          plan?.plan_details?.jv_medical_plan_id === selectedPlan?.plan?.external_id,
      );

      if (
        !isDefaultPrevSelected ||
        (isEmpty(previousSelectedPlans) && !isEmpty(availablePlans)) ||
        (isPlanSelected && !matchedWithSelectedPlan)
      ) {
        invalidPlanMessage.current = null;
      }

      if (isLoading) {
        setShowPreviousSelectedPlanMessage(true);
      }
      if (!isLoading && availablePlans?.length > 0) {
        if (
          !result?.matchingPlan ||
          !previousSelectedPlans ||
          !isDefaultPrevSelected ||
          (isPlanSelected && !matchedWithSelectedPlan)
        ) {
          setShowPreviousSelectedPlanMessage(false);
        }
      }
    } else {
      setShowPreviousSelectedPlanMessage(false);
      if (availablePlans?.length > 0) {
        const isPlanSelected = availablePlans?.some(
          (availablePlan) => selectedPlan?.plan?.external_id === availablePlan?.plan?.external_id,
        );
        if (!isPlanSelected && !selectPlanEmptyRef.current) {
          selectHealthPlanStoreOnly({});
          selectPlanEmptyRef.current = true; // Mark the effect as run
        }
      }
    }
  }, [result?.previousSelectedMatchedPlan, availablePlans, handleSelectHealthPlan, isDefaultPrevSelected]);

  const renderRecommendations = () => {
    // Will check if showPrevSelectedPlan is true then will call moveSelectedPlanToTop function to re-arrange the availablePlans array and move selected plan array to index 0, else will keep availablePlans as it is!
    const sortedAvailablePlan = showPrevSelectedPlan
      ? moveSelectedPlanToTop(availablePlans, result)
      : availablePlans;

    const recommendations = sortedAvailablePlan?.map((rec: RecommendationType, idx: number) => {
      // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false. Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
      const plan = new Plan(rec, !is_real_cost_before_tax_benefit_enabled);

      const isRecPreviouslySelected = showPrevSelectedPlan
        ? rec.plan.external_id === result?.previousSelectedMatchedPlan?.plan_details?.jv_medical_plan_id
        : false;

      return (
        <Recommendation
          dataTestId={rec.plan.external_id}
          idx={idx}
          key={rec.plan.external_id}
          client={client}
          isEntered={rec.plan.carrier_name === 'Custom'}
          isImprovedRecExplanationEnabled={isImprovedRecExplanationEnabled}
          viewPlan={viewPlanDetails}
          plan={plan}
          isFamily={isFamily}
          score={plan.picwellScore}
          hsaContributionLimit={hsaContributionLimit}
          maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution}
          choosePlan={() => handleSelectHealthPlan(rec)}
          deselectPlan={handleDeselect}
          isSelected={
            isPlanSelected ? selectedPlan.plan.external_id === rec.plan.external_id : isRecPreviouslySelected
          }
          killMedRec={killMedRec}
          isPlanComparisonEnabled={isPlanComparisonEnabled}
          handleOpenPlanEntry={planEntryOnOpen}
          searchProviderLinks={searchProviderLinks}
          plyData={peopleLikeYou}
        />
      );
    });

    // Splice secondary content into recommendations list
    if (device === 'mobile') {
      if (recommendations.length > 4) {
        recommendations.splice(4, 0, buildPlanBannerCard as JSX.Element);
        recommendations.splice(3, 0, predictionsBannerCard as JSX.Element);
        recommendations.splice(2, 0, accountsBannerCard as JSX.Element);
        recommendations.splice(1, 0, providersSearchBannerCard as JSX.Element);
      }
      recommendations.splice(0, 0, renderMemberSelectionCard as JSX.Element);
    }

    if (device === 'tablet') {
      recommendations.splice(
        1,
        0,
        <HorizontalScrollBar>
          {renderMemberSelectionCard}
          {providersSearchBannerCard}
          {is_improved_recommendation_explanation_enabled ? predictionsBannerCard : accountsBannerCard}
          {is_improved_recommendation_explanation_enabled ? accountsBannerCard : predictionsBannerCard}
          {buildPlanBannerCard}
        </HorizontalScrollBar>,
      );
    }

    return recommendations;
  };

  const renderPageContent = () => {
    if (selectedProductIsBc) {
      return null;
    }

    if (showClientSurvey && availablePlans.length === 0) {
      return (
        <FadeUp isOpen={isShowResults}>
          <ContentfulTextNote sectionKey="health_section" />
          <NoEligiblePlans goToClientSurvey={goToClientSurvey} goToSupplemental={goToSupplemental} />
        </FadeUp>
      );
    }

    const allTextNotes = retrieveContentfulData<ContentfulTextNoteType[]>('health_section.text_notes') || [];
    const planYearSpecific = allTextNotes.filter(
      (note) => note.plan_year === selectedPublicationInfo?.publication.plan_year.toString(),
    );

    let contentfulTextNote: JSX.Element;
    if (planYearSpecific.length) {
      const note = planYearSpecific[0];
      contentfulTextNote = <ContentfulTextNote note={note} />;
    } else {
      // Render legacy singular text note
      contentfulTextNote = <ContentfulTextNote sectionKey="health_section" />;
    }

    return (
      <>
        <PageLayout page="results">
          <GridContainer>
            <div>
              <FadeUp isOpen={isShowResults && !isHouseholdLoading}>
                <Stack gap={4}>
                  <PlanChangeMessage>
                    {invalidPlanMessage.current && (
                      <InvalidPlanSelectionMessage invalidPlanMessage={invalidPlanMessage.current} />
                    )}
                  </PlanChangeMessage>
                  {contentfulTextNote}
                  <PlanCards>
                    {renderRecommendations()}
                    {renderWaiveCoverage}
                  </PlanCards>
                </Stack>
              </FadeUp>
              <FadeUp isOpen={isShowResults && isHouseholdLoading}>
                <MemberLoadingContainer>
                  <Spinner size="large" color="--border-gray" />
                </MemberLoadingContainer>
              </FadeUp>
            </div>
            {device === 'desktop' && (
              <FadeUp isOpen={isShowResults}>
                <Sidebar>
                  {renderMemberSelectionCard}
                  {providersSearchBannerCard}
                  {docFinderEnabled && <MemberDivider className="bottom-border" />}
                  {is_improved_recommendation_explanation_enabled
                    ? predictionsBannerCard
                    : accountsBannerCard}
                  {is_improved_recommendation_explanation_enabled
                    ? accountsBannerCard
                    : predictionsBannerCard}
                  {buildPlanBannerCard}
                </Sidebar>
              </FadeUp>
            )}
          </GridContainer>
          {device === 'mobile' && availablePlans.length < 5 && (
            <BottomCards>
              {providersSearchBannerCard}
              {is_improved_recommendation_explanation_enabled ? predictionsBannerCard : accountsBannerCard}
              {is_improved_recommendation_explanation_enabled ? accountsBannerCard : predictionsBannerCard}
              {buildPlanBannerCard}
            </BottomCards>
          )}
        </PageLayout>
        {isPlanComparisonEnabled && (
          <CompareBar
            killMedRec={killMedRec}
            isImprovedRecExplanationEnabled={isImprovedRecExplanationEnabled}
            handleShowCompareModal={compareOnOpen}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div id="result-page">
        <ContentfulHeaderWrapperForHealthSection
          numAvailablePlans={availablePlans.length}
          hideSubtext={isShowLoading || selectedProductIsBc}
          useHeaderAlternateText={selectedProductIsBc}
          animate={isFirstPageView}
          showPreviousSelectedPlanMessage={showPreviousSelectedPlanMessage}
          isReturnUser={!!isReturnUser}
          hidePlanScoringText={killMedRec}
        />
        {availablePlans.length !== 0 && (
          <FadeUp isOpen={isShowResults}>
            <ClientDisclaimer
              hasSpouse={hasSpouse}
              maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution}
            />
          </FadeUp>
        )}
        <AriaHiddenAlert>{hiddenAlert}</AriaHiddenAlert>
        <FadeUp timeout={300} delay={750} isOpen={isShowLoading}>
          <LoadingScreen
            household={household}
            handleOnLoadingComplete={handleOnLoadingComplete}
            isLoading={isLoading}
          />
        </FadeUp>
        {renderPageContent()}
      </div>

      <EducationalDrawer
        handleClose={hsaOnClose}
        isOpen={hsaOpen}
        infoType="hsa"
        individualHsaContributionLimit={individualHsaContributionLimit}
        familyHsaContributionLimit={familyHsaContributionLimit}
        employerContribution={employerHSAContributionMax}
        selectedPlan={selectedPlan}
        shouldShowEducationalVideo={!is_educational_videos_enabled || builderGoSettings?.show_hsa_video}
      />

      <EducationalDrawer
        isOpen={hraOpen}
        handleClose={hraOnClose}
        infoType="hra"
        employerContribution={employerHRAContributionMax}
        selectedPlan={selectedPlan}
        shouldShowEducationalVideo={!is_educational_videos_enabled || builderGoSettings?.show_hra_video}
      />

      <EducationalDrawer
        isOpen={fsaOpen}
        handleClose={fsaOnClose}
        infoType="fsa"
        employerContribution={0}
        selectedPlan={selectedPlan}
        shouldShowEducationalVideo={!is_educational_videos_enabled || builderGoSettings?.show_fsa_video}
      />
      <ExplainerModal handleClose={predictionsOnClose} isOpen={predictionsOpen} plyData={peopleLikeYou} />

      <PlanEntryModal
        handleClose={planEntryOnClose}
        isOpen={planEntryOpen}
        getRecommendations={(plan) => handleEphemeralPlanRecommendation(plan)}
        plan={enteredPlan}
        savePlan={saveEphemeralPlan}
        individualHsaContributionLimit={individualHsaContributionLimit}
        familyHsaContributionLimit={familyHsaContributionLimit}
        isFamily={isFamily}
        maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution}
      />

      <CompareModal
        handleClose={compareOnClose}
        isOpen={compareOpen}
        // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false.
        // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
        selectedPlan={
          isPlanSelected
            ? new Plan(
                selectedPlan as RecommendationType,
                !is_real_cost_before_tax_benefit_enabled, // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
              )
            : {}
        }
        choosePlan={handleSelectHealthPlan}
        deselectPlan={handleDeselect}
        hsaContributionLimit={hsaContributionLimit}
        maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution}
        client={client}
        killMedRec={killMedRec}
        coverageTier={coverageTier}
        isImprovedRecExplanationEnabled={isImprovedRecExplanationEnabled}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, ResultPageStateProps>({
  client: makeSelectConfigField('client'),
  selectedProduct: makeGetSelectedProduct(),
  showClientSurvey: makeSelectShowClientSurvey(),
  killMedRec: makeGetBuilderGoSetting('kill_med_rec'),
  isLoading: makeSelectCommercialField('isLoading'),
  household: makeGetHousehold(),
  hasSpouse: makeGetHasSpouse(),
  isFamily: makeGetIsFamily(),
  availablePlans: makeGetPlans(),
  maximumAvailableAnnualSihraContribution: makeSelectCommercialField('annual_sihra_contribution'),
  peopleLikeYou: makeSelectCommercialField('people_like_you'),
  hsaContributionLimit: makeGetHsaContributionLimit(),
  individualHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitIndividual'),
  familyHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitFamily'),
  isDirty: makeSelectGlobalField('isDirty'),
  isFullBenefitEnabled: makeSelectConfigField('is_fbs_enabled'),
  canWaiveCoverage: makeSelectConfigField('can_waive_coverage'),
  selectedPlan: makeSelectCommercialField('selectedHealthPlan'),
  isPLYEnabled: makeSelectConfigField('is_ply_enabled'),
  enteredPlan: makeSelectCommercialField('entered_plan'),
  isSpousalPlanEnabled: makeSelectConfigField('is_spousal_plan_comparison_enabled'),
  isPlanComparisonEnabled: makeSelectConfigField('is_plan_comparison_enabled'),
  supplementalPlans: makeSelectOverviewField('supplemental_plans_full_household'),
  isFirstPageView: selectIsFirstPageView(RESULT_PATH),
  searchProviderLinks: makeSelectConfigField('provider_search_links'),
  selectedMemberIds: makeGetHealthPlanMemberIds(),
  survey: makeSelectSurvey(),
  isCoverageWaived: makeSelectCommercialField('isCoverageWaived'),
  coverageTier: makeGetCoverageTier(),
  jvp: makeSelectGlobalField('jvp'),
  isDemoMode: makeSelectGlobalField('isDemoMode'),
  planYear: makeGetSelectedPublicationPlanYear(),
  validatedSegmentSlug: makeGetSelectedPublicationSegmentSlug(),
  bcUrlHash: makeSelectGlobalField('bcUrlHash'),
  builderCustomerKey: makeSelectConfigField('builder_customer_key'),
  incentiveSurvey: makeSelectProfileField('incentiveSurvey'),
  isIncentiveSurveyLoading: makeSelectProfileField('incentiveSurveyIsLoading'),
  incentiveSurveyAnswers: makeSelectProfileField('incentiveSurveyAnswers'),
  resultPageIncentiveSurvey: makeSelectProfileField('resultPageIncentiveSurvey'),
  isReturnUser: makeSelectJvpField('isReturnUser'),
  previousSelectedPlans: makePreviousSelectedPlan('previousSelectedPlan'),
  tierCode: makeGetTierCodeData(),
  isPlanSelected: makeIsPrevPlanSelected(),
  builderGoSettings: makeGetBuilderGoSettings(),
});

export function mapDispatchToProps(dispatch): ResultPageDispatchProps {
  return {
    getRecommendations: () => dispatch(getResultsPageData()),
    resetSelectedSupplementalPlans: () => dispatch(setDefaultSelectedPlans([], {})),
    clearSupplementalCache: () => dispatch(clearSupplementalPlansCache()),
    viewPlanDetails: (planId) => dispatch(push(`${VIEW_PLAN_PATH}/${planId}`)),
    goToClientSurvey: () => dispatch(push(PROFILE_PATH)),
    selectHealthPlan: (plan) => dispatch(selectHealthPlan(plan)),
    selectHealthPlanStoreOnly: (plan) => dispatch(selectHealthPlanStoreOnly(plan)),
    updateActiveMembers: (members) => dispatch(setActiveHouseholdMembers(members)),
    goToSupplemental: () => dispatch(push(OVERVIEW_PATH)),
    saveEphemeralPlan: (plan) => dispatch(changeFormValue(plan, 'entered_plan')),
    getRecommendationsWithEphemeralPlan: (plan) => dispatch(recommendWithEphemeralPlan(plan)),
    waiveCoverage: () => dispatch(waiveHealthCoverage()),
    fetchSupplementalPlans: () => dispatch(getSupplementalPlans()),
    lockFuturePages: () => dispatch(handleUnlockMainNav(RESULT_PATH)),
    setSelectedMemberIds: (memberIds: string[]) => dispatch(setSelectedMemberIds(memberIds)),
    handleSavePregnancyAnswer: (value: string) =>
      dispatch(changeSurveyValueFromResultPage(value, 'plan_child_question')),
    createUpdateHouseholdAndIncentiveSurvey: () => dispatch(updateHouseholdFromResultPage()),
    submitIncentiveSurveyResponses: (responses: Record<string, IncentiveSurveyAnswer | null>) =>
      dispatch(submitResultIncentiveSurvey(responses)),
    setProfileIncentiveSurvey: (data) => dispatch(setIncentiveSurvey(data)),
    updatePreviousSelectedPlan: (data) => dispatch(updatePreviousSelectedPlan(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ResultPage);
